<template>
    <div class="lds-ellipsis" :style="'width: ' + width">
        <div class="dotContainer">
            <div class="dot dotOne"/>
            <div class="dot dotTwo"/>
        </div>
        <div class="dot dotThree"/>
        <div class="dot dotFour"/>
    </div>
</template>

<script>
export default {
    name: "Ellipsis",
    props: {
        width: {
            type: String,
            default: "80px"
        }
    },
}
</script>

<style scoped>
.lds-ellipsis {
    display: flex;
    justify-content: space-between;
    aspect-ratio: 80 / 13;
}
.dotContainer {
    aspect-ratio: 1 / 1;
    height: 100%;
}
.dot {
    aspect-ratio: 1 / 1;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.dotOne {
    animation: lds-ellipsis1 0.6s infinite;
}
.dotTwo {
    margin-top: -100%;
    animation: lds-ellipsis2 0.6s infinite;
}
.dotThree {
    animation: lds-ellipsis2 0.6s infinite;
}
.dotFour {
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(250%, 0);
    }
}

</style>