<template>
    <div class="lds-ring" ref="lds-ring" :style="'width: ' + width">
        <div :style="'border-width: ' + borderWidth + '; border-color: ' + color + ' transparent transparent transparent'"/>
        <div :style="'border-width: ' + borderWidth + '; border-color: ' + color + ' transparent transparent transparent'"/>
        <div :style="'border-width: ' + borderWidth + '; border-color: ' + color + ' transparent transparent transparent'"/>
        <div :style="'border-width: ' + borderWidth + '; border-color: ' + color + ' transparent transparent transparent'"/>
    </div>
</template>

<script>
import {ResizeSensor} from "css-element-queries";

export default {
    name: "TestLoader2",
    props: {
        width: {
            type: String,
            default: "80px"
        },
        color: {
            type: String,
            default: "#fff"
        },
        thickness: {
            type: String,
            default: null
        },
        reactiveBorderWidth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            borderWidth: '8px'
        }
    },
    mounted() {
        if (!this.thickness) {
            this.getBorderWidth()
            if (this.reactiveBorderWidth) {
                new ResizeSensor(this.$refs["lds-ring"], () => {
                    this.getBorderWidth()
                })
            }
        } else {
            this.borderWidth = this.thickness
        }
    },
    methods: {
        getBorderWidth() {
            this.borderWidth = (this.$refs["lds-ring"].clientWidth * .1) + 'px'
        }
    }
}
</script>

<style scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    aspect-ratio: 1 / 1;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-style: solid;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>